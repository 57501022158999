
import { OauthClient } from "@/types";
import apiFetch from "@/utils/request";
import { Vue } from "vue-class-component";

class CreateOauthClient extends Vue {
  oauthClient: OauthClient = {
    client_id: "",
    secret: "",
  };
  error: string = "";
  async save() {
    try {
      await this.createOauthClient();
      this.$router.push({ path: "/oauth-client" });
    } catch (err) {
      this.error = err.response.data.error.message;
    }
  }
  async createOauthClient() {
    const newOauthClient: OauthClient = { ...this.oauthClient };
    await apiFetch.oauthClient.createOauthClient(newOauthClient);
  }
}
export default CreateOauthClient;
